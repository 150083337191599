* {
  font-family: "Montserrat";
  font-size: 12px;
}

a {
  text-decoration: none !important;
}

body {
  background: #F4F5FA !important;

}

.maplabel {
  font-weight: bold;
  font-size: .75rem !important;
}

.fa-file-pdf.file-thumbnail {
  color: tomato;
}

.fa-file-word.file-thumbnail {
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail {
  color: #198754;
}


footer {
  background-color: #647393;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 40px;
}

footer a {
  text-decoration: none;
}

.header {
  background: none;
}

.header,
.header a {
  color: #161e2c
}

.sidebar-header {
  color: #fff;
}

.btn-floating {
  border-radius: 50% !important;
}

.field-label {
  font-size: smaller;
}

.field-data {
  border-bottom: 1px solid #ccc;

}

.related-list-table a {
  text-decoration: none;
}

.btn-primary,
.btn-info {
  background-color: #6C757D !important;
  color: #fff !important;
  border-color: #6C757D !important;
}

.login-form {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  margin-top: 5rem;
}

.login-form i {
  color: #f7981c;

}

.login-section {
  border-left: 1px solid #ccc;
}

.login-form img {
  width: 100%;
}

.login-form .vr {
  border-right: 1px solid #ccc;
}

.data-table a {
  text-decoration: none;
}

.data-table th {
  background-color: #91969E !important;
  color: #fff;
  border: 1px solid #91969E;
  font-weight: 400;
  text-transform: uppercase;
  white-space: nowrap;
}

.data-table {
  border: 1px solid #91969E;
  border-radius: 5px;
}

.view-form label {
  font-size: smaller;
  display: block;
  text-transform: uppercase;
  margin-top: 1rem;
}

.view-form-header {
  background-color: #6c757d;
  color: #fff;
  padding: .5rem;
  border-radius: 5px 5px 0 0;
}

.form-detail,
.form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255, 255, 255, .75);
}

.view-form span {
  display: block;
  border-bottom: 1px solid #ccc;

}

.fl {
  padding-left: 0px !important;
}

.fr {
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }


}

input[type=number].no-spinner::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-pdf__Page {
  margin-top: 10px;
}

.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.myTable {
  width: 800px;
}

.fc-event {
  cursor: pointer;
}

table.fc-col-header {
  background-color: #161e2c;
}

table.fc-col-header a {
  color: #fff !important;
}

th.fc-col-header-cell {
  padding: .5rem 0 .5rem 0 !important;
  /*border: 1px solid #161e2c !important;*/
}

.btn-outline-primary {
  border: 1px solid !important;
  color: #333 !important;


}

.btn-outline-primary:hover {
  background-color: #91969E !important;
  color: #fff !important;
}

.btn-custom {
  border: 1px solid #1c3df7 !important;
  color: #fff !important;
  background: #1c3df7 !important;
}

/*
 * Progressbar
 */

.bar {
  --d: 1rem;
  /* arrow depth */
  --gap: 0.3rem;
  /* arrow thickness, gap */

  display: flex;
  margin-right: var(--d);
}

.bar-step {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6rem var(--d);
  margin-right: calc(var(--d) * -1 + var(--gap));
  background: #d3d7dd;
  color: #23468c;
  clip-path: polygon(0% 0%,
      calc(100% - var(--d)) 0%,
      100% 50%,
      calc(100% - var(--d)) 100%,
      0% 100%,
      var(--d) 50%);

  font-size: .8rem;
}

.bar-step:first-child {
  clip-path: polygon(0% 0%,
      calc(100% - var(--d)) 0%,
      100% 50%,
      calc(100% - var(--d)) 100%,
      0% 100%);
}


.bar-step.active {
  background: #23468c;
  color: #fff;
}

.bar-step.completed {
  background: rgb(79, 167, 86);
  color: #fff;
}

.ibs-edit-form {
  background-color: #fff;
  margin-bottom: 1rem;
}

.card-header {
  background-color: rgb(99, 115, 132) !important;
  color: #fff !important;
}

.nav-link[aria-selected=false] {
  color: #fff !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #333 !important;
}

.nav-link {
  color: #fff;
}

.section-header {
  background-color: #91969E;
  color: #fff;
  padding: .5rem;
  margin-top: 1rem !important;
  text-transform: uppercase;
}

.form-select[required] {
  border-left: 4px solid red;
}

.Select[required] {
  border-left: 4px solid red;
}


input.form-control[required] {
  border-left: 4px solid red;
}

input.form-control[required] {
  border-left: 4px solid red;
}

textarea.form-control[required] {
  border-left: 4px solid red;
}

.form-select[required] {
  border-left: 4px solid red;
}

.custom-select {
  border-left: 4px solid red;
}


._3ybTi {
  display: inline;
}

.mobilecard {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 1rem;
}

.mobilecard span,
.mobilecard a {
  width: 50%;
  padding-bottom: .7rem;
}

.ql-container {
  min-height: 10rem;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto;
  width: 100%;
}

.quill span {
  border: none;
}

.chat-input-group {
  text-align: right;
}

.chat-input-group span {
  border: none;
  display: inline-block;
}

.chat {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  min-height: 200px;

}

.sendbtn {
  background: none;
  border: 1px solid #007bff;
  padding: .3rem 1rem .3rem .5rem;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;

}

.chat .chat-input {
  outline: none;

  border: 1px solid #e6e6e6;
  padding: 1rem;
  height: 8rem;

}



/* .dropdown-menu.show {
  right :0 !important;
  left: auto !important;
  padding: 0;
} */

.dropdown-toggle::after {
  display: none !important;
}

/* 
.dropdown-toggle{
  background: none !important;
} */

/* .dropdown-toggle .show .nav-link{
  background: none;
} */



.alert {
  margin: 0 !important;
}

/* .dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active{
  background: none;
} */

.notification-area {
  width: 400px;
  position: absolute;
  right: 3rem;
  left: auto;
  top: 3rem;
  z-index: 10;
}

.icon-wrapper {
  position: relative;
  float: left;
}

*.icon-blue {
  color: Chartreuse;
}



.cic {
  text-align: center;
  vertical-align: middle;
}

.notify-number {
  background: tomato;
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 51%;
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 3px;
  font-size: .8rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
}


.fade.alert.alert-success.alert-dismissible.show {
  border-radius: 0;
  ;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  color: var(--bs-nav-tabs-link-active-color) !important;
  background-color: white !important;
}

.filter-section .nav-link {
  color: white !important
}

.filter-section .nav-link.active {
  border-bottom: 1px solid white !important
}

.capitalFields {
  text-transform: capitalize;
}

.chat span {
  min-height: auto;
}

* {
  font-family: "Montserrat";
  font-size: 12px;
}

a {
  text-decoration: none !important;
}

body {
  background: #F4F5FA !important;
}

.maplabel {
  font-weight: bold;
  font-size: .75rem !important;
}

/* Add Calender Css */
.highlighted-blue {
  color: blue;
  background-color: #EEE8AA !important;
}

.react-calendar__navigation__arrow {
  display: none !important;
}

.react-calendar {
  width: 100% !important;
  max-width: 100% !important;
  border-radius: 4px;
}


/* sppinner start */
.sk-cube-grid {
  width: 5rem;
  height: 5rem;
  margin: 15% auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #4F6479;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@keyframes sk-cubeGridScaleDelay {

  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

/* sppiner end */

/* START || Remove number type dropdown || 05 Jan 2023 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.input-group-hours-class {
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: stretch !important;
  width: 75px !important;
}

.weekend-yellow-highlight {
  background-color: yellow !important;
  padding: 5px 25px 5px 25px;
}

@media only screen and (max-width: 767px) {
  .weekend-yellow-highlight {
    background-color: yellow !important;
    padding: 5px 12px 5px 12px;
  }

  .input-group-hours-class {
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: stretch !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
}

.table-min-div {
  overflow-x: auto;
  width: 100%;
}


::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* END || Remove number type dropdown || 05 Jan 2023 */

/* START 11 January */
.copyright-container {
  box-sizing: border-box;
  padding-right: 20px;
  padding-left: 20px;
  background-color: rgb(38, 109, 141);
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: white;
  padding-top: 15px;
  height: 60px;
}

/* END 11 January */

/* 05 March 2024 shimmer table row hide || START */
.custom-shimmer-class {
  .shimmer-table-row--header {
    display: none !important;
  }
}

/* 05 March 2024 shimmer table row hide || END */



/* SIDEBAR SCROLLBAR CSS || START */
.list-unstyled {
  border-top: 1px solid rgb(255, 255, 255);
  margin-bottom: 0px;
  overflow-y: auto;
  height: 60vh;
  scrollbar-color: rgb(99, 115, 132) rgb(79, 100, 121);
  position: absolute;
  padding-bottom: 70px;
}

.scrollbar-thin {
  scrollbar-width: thin;
  overflow-y: auto;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .list-unstyled {
    height: 45vh;
  }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
  .list-unstyled {
    height: 50vh;
  }
}

/* SIDEBAR SCROLLBAR CSS || END */
.rmsc .dropdown-container {
  position: relative;
  outline: 0;
  background-color: var(--rmsc-bg);
  border: 1px solid var(--rmsc-border);
  border-radius: var(--rmsc-radius);
  height: 32px !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  margin-top: -7px !important;
}