body {
    overflow: hidden;
}

.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
}

#sidebar {
    min-width: 200px;
    max-width: 215px;
    transition: all 0.3s;
    background-color: #4f6479;
    color: #fff;
    /* backdrop-filter: opacity(0.5); */
    font-size: small;

    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    overflow: hidden;


    background-position: center;
    background-size: cover;
    border-right: 1px solid #6c757d;
    transition: .5s;
    position: relative;
    animation-name: hide;
    animation-duration: .5s;
}

#sidebar::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, #4468bb 30%, #fff 100%);
    content: '';
    opacity: .8;
    z-index: -1;
}

#minisidebar {
    min-width: 90px;
    max-width: 90px;
    transition: all 0.3s;
}

#sidebar.hide {
    /* display: none; */

    width: 0;
    min-width: 0;
    left: -100%;

}

#sidebar.show {
    /* display: none; */
    width: 275px;
    min-width: 200px;
    max-width: 290px;
    left: 0;
    animation-duration: .1s;

}

#minisidebar.active {
    display: none;
}

#sidebar .sidebar-header,
#minisidebar .sidebar-header {
    padding: 20px;
    text-align: center;
}

#sidebar ul li a,
#minisidebar ul li a {
    padding: 10px;
    font-size: 1em;
    display: block;
    text-decoration: none;
    padding-left: 1rem;
    color: #fff;
}

#sidebar ul li a:hover,
#minisidebar ul li a:hover {
    color: #fff;
    background-color: #3465ce5e;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background-color: #3465ceab;
    border-top: none !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
    overflow: scroll;
    height: 200px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {

    /* #sidebar {
        margin-left: -215px;
    } */
    #sidebar.active {
        display: block;
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
}

.minisidebar-header {
    width: 91px;
}

.custom-link-sidebar {
    padding: 10px;
    font-size: 16px;
    display: block;
    padding-left: 1rem;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
}

.active>.custom-link-sidebar {
    color: #2F3F4F;
    background-color: #ffffff;
    border-top: none !important;
    width: 100%;
    font-size: 14px;
}

/* START 16 NOV */

.member-pic {
    width: 100%;
    /* height: 100px; */
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.member-name {
    font-size: 10px;
    color: white;
    font-family: Montserrat;
    font-weight: bolder;
}

.content-text {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* background-image: linear-gradient(to right, #889cc9, #d3d9e4); */
    background-color: #637384;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 1px;
    padding-right: 1px;

}

.menu-item-txt {
    font-size: 14px;
}

li i {
    font-size: 14px;
}

/* ::-webkit-scrollbar {
    width: 50px;
} */


/* This Media query use for mobile device */
@media only screen and (max-width: 767px) and (min-width: 200px) {
    .table-min-div {
        overflow: scroll;
        width: 100%;
    }

    /* .css-b62m3t-container {
        width: 100px;
    } */
}